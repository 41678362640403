export const allSteps = (translate) => {
  return [
    {
      name: "birthdate",
      type: "calendar",
      content: translate("questions.birth.title"),
      subtitle: translate("questions.birth.subtitle"),
      componentName: "dateOfBirth",
      placeholder: "DD-MM-YYYY",
      previousQuestionId: false,
    },
    {
      name: "capital",
      type: "capital",
      content: translate("questions.capital.title"),
      subtitle: translate("questions.capital.subtitle"),
      componentName: null,
      placeholder: translate("questions.capital.placeholder"),
      previousQuestionId: true,
    },
    {
      name: "zipcode",
      type: "number",
      contentType: "text",
      content: translate("questions.zipcode.title"),
      subtitle: translate("questions.zipcode.subtitle"),
      componentName: "postal_code_question",
      placeholder: translate("questions.zipcode.placeholder"),
      previousQuestionId: true,
    },

    {
      name: "gender",
      type: "radio",
      content: translate("questions.gender.title"),
      subtitle: translate("questions.gender.subtitle"),
      componentName: null,
      previousQuestionId: true,
    },
    {
      name: "ipa",
      type: "radio",
      content: translate("questions.ipa.title"),
      subtitle: "",
      componentName: null,
      previousQuestionId: true,
    },
  ];
}
