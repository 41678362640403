import IdCardBiometryController from "./idCardBiometry.controller";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import SingleSelectQuestion from "get-life-storybook-ts/lib/components/Inputs/Input_Select/SingleSelectQuestion/SingleSelectQuestion";
import FormButton from "../../components/Components/FormBuilder/FormButtons";
import "./biometry.scss";

const IdCardBiometry = () => {

    const {
        documentSelected,
        isDocumentSelected,
        neededDocuments,
        selectDocument,
        setDocumentSelected,
        translate,
        urlBiometry
    } = IdCardBiometryController();

    return (
        <>
            {
                !isDocumentSelected ? (
                    <div className="flex flex-col gap-5">
                        {neededDocuments && neededDocuments.length > 0 && (
                            <>
                                <SingleSelectQuestion
                                    name="documents"
                                    title={translate("idCard.biometry.title")}
                                    options={neededDocuments}
                                    onValueChange={(e) => setDocumentSelected(e)}
                                    defaultVariant={"Icon"}
                                    defaultSize="L"
                                    columns={1}
                                    tooltip={
                                        { title: translate("idCard.biometry.tooltip.title"), }
                                    }
                                />
                                <div className="signaturit-logo-container">
                                    <img src="https://storage.googleapis.com/getlife-bucket-1/public/image/signaturit_logo.png" alt="" />
                                </div>
                                <FormButton
                                    showButton={true}
                                    isLoading={false}
                                    isOneChoinceQuestion={false}
                                    componentName={"biometry"}
                                    translate={translate}
                                    handleSubmit={() => selectDocument()}
                                    question={{type:"undefined"}}
                                    answer={undefined}
                                    questionStack={[]}
                                    goBack={false}
                                    customDisabled={documentSelected === ""}
                                    validateQuestionDateInput={undefined}
                                    isSticky={true}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    <div id="checkoutPay-requestNote" className="checkoutPay-requestNote">
                        {urlBiometry !== "" ? (
                            <iframe
                                width="100%"
                                height="100%"
                                title="contract-viewer"
                                src={urlBiometry}
                                allow="camera"
                            />
                        ) : (
                            <div className="checkoutSign-contractContainer__spinner">
                                <Spinner height="12" width="12" />
                            </div>
                        )}
                    </div>
                )
            }
        </>
    )

}

export default IdCardBiometry;