import { useContext, useEffect } from "react";
import { I18nContext } from "../../context/i18n.context";
import {
  GetlifeContext,
  LanguageT,
} from "../../context/GetLifeContext.context";
import { useLocation } from "react-router";
import { PRE_PAYMENT_STATUS } from "../../Routes/RecoverUserStatus";
import { IconNameT, IconSizeT, useDevice } from "get-life-storybook-ts";
import { PHONE, PHONE_POST } from "../../constants/Globals";
import { DOMAIN } from "../../constants/Global";

type CountriesI = {
  [k in LanguageT]: {
    phone: string | undefined;
    handleCall: (() => void) | undefined;
    logo?: IconNameT;
    logoSize: IconSizeT;
    logoClassname?: string;
    secondaryLogo?: IconNameT;
    secondaryLogoClassname?: string;
    backgroundHeader?: string;
  };
};

const LayoutController = () => {
  const {
    domain,
    loading,
    navbarStickyInfo,
    nodeElementAlert,
    nodeWeCallYou,
    notification,
    progressBar,
    setShowHeader,
    showFooter,
    showHeader,
    userStatus,
    toast,
    toastIsOpen,
    logo,
  } = useContext(GetlifeContext);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { isMobile } = useDevice();

  const location = useLocation();

  const route = location.pathname;

  const closePage = () => {
    window.location.replace(`${process.env.REACT_APP_DOMAIN_URL}`);
  };

  useEffect(() => {
    if (!showHeader) {
      setShowHeader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const getPhone = PRE_PAYMENT_STATUS.includes(userStatus) ? PHONE : PHONE_POST;

  const displayITFacileIcon =
    DOMAIN === "it" && window.location.pathname.includes("/partners/facile");

  const multiCountries = () => {
    const countries: CountriesI = {
      fr: {
        phone: getPhone,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
      },
      es: {
        phone: getPhone,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
      },
      en: {
        phone: undefined,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
      },
      pt: {
        phone: undefined,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
      },
      it: {
        phone: getPhone,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
        ...(displayITFacileIcon
          ? {
              secondaryLogo: isMobile ? undefined : "FacileCollaborationIcon",
              secondaryLogoClassname: isMobile
                ? undefined
                : "layoutItSecondaryLogo",
            }
          : {}),
      },
      wl_es: {
        phone: getPhone,
        handleCall: undefined,
        logo: "LogoAxaBlue",
        logoSize: "M",
        backgroundHeader: "white",
        logoClassname: "WLLogoPadding",
      },
      sql_es: {
        phone: getPhone,
        handleCall: undefined,
        logo: "LogoLife5Blue",
        logoSize: "L",
        backgroundHeader: "white",
      },
    };

    return countries[domain];
  };

  const closeButton: boolean = route.includes("login");

  return {
    closeButton,
    closePage,
    loading,
    multiCountries,
    navbarStickyInfo,
    nodeElementAlert,
    nodeWeCallYou,
    notification,
    progressBar,
    showFooter,
    showHeader,
    translate,
    toast,
    toastIsOpen,
    logo,
  };
};

export default LayoutController;
