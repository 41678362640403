import React from "react";
import OtpDisabled from "../views/LoginPage/OtpDisabled";
import PasswordChange from "../views/Reset/component/PasswordChange";
import Reset from "../views/Reset/Reset";
import NotFoundPage from "../views/NotFoundPage/NotFoundPage";
import LoginOtpPage from "../views/LoginPage/LoginOtpPage";
import LoginPage from "../views/LoginPage/LoginPage";
import WaitingSignature from "../views/StepsCalculatorPage/Components/PaymentGateway/components/WaitingSignature";
import WaitingValidation from "../views/StepsCalculatorPage/Components/PaymentGateway/components/WaitingValidation";
import PrePostStackAnimation from "../components/Components/lotties/PrePostStackAnimation";
import NotCoverage from "../views/NotCoverage/NotCoverage";
import { Navigate } from "react-router";
import PostPaymentMain from "../views/Questions/PostPayment/PostPaymentMain";
import BeneficiariesControlCapital from "../views/Questions/PostPayment/components/BeneficiariesControlCapital";

import CardValidation from "../views/CardValidation/CardValidation";
import StepsCalculatorPage from "../views/StepsCalculatorPage/StepCalculatorPage/StepsCalculatorPage.component";
import PreStackMain from "../views/Questions/PreStackQuestions/PreStackMain";
import ProductStackQuestionsMain from "../views/Questions/ProductQuestions/ProductStackQuestionsMain";
import DecisionPage from "../views/DecisionPage/DecisionPage.component";
import ConfirmationPage from "../views/ConfirmationPage/ConfirmationPage.component";
import IncomingUsersPage from "../views/PauseForBreath/IncomingUsersPage.component";
import { GetlifeCalculatorProvider } from "../context/GetLifeCalculator.context";
import CalculatorPageES from "../views/Calculator/ES/CalculatorPageES.component";
import CalculatorPageFR from "../views/Calculator/FR/CalculatorPageFR.component";
import CalculatorPageIT from "../views/Calculator/IT/CalculatorPageIT.component";
import CalculatorPageSQL_ES from "../views/Calculator/SQL_ES/CalculatorPageSQL_ES.component";
import ResumeVelogica from "../views/ResumeVelogica/ResumeVelogica";
import BeneficiariesFreeChoice from "../views/Questions/PostPayment/components/BeneficiariesFreeChoice/BeneficiariesFreeChoice.component";
import PaymentGateway from "../views/StepsCalculatorPage/Components/PaymentGateway/PaymentGateway.component";
import RastreatorContainer from "../views/Rastreator/Rastreator";
import Telesubscription from "../views/Telesubscription/Telesubscription";
import WelcomeLead from "../views/WelcomeLead/WelcomeLead";
import FacilePartner from "../views/FacilePartner/FacilePartner";
import AgentConfirmation from "../views/StepsCalculatorPage/Components/PaymentGateway/components/AgentConfirmation";
import CalculatorPageWL_ES from "../views/Calculator/WL_ES/CalculatorPageWL_ES.component";
import TermsAndConditions from "../views/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicySummary from "../views/PrivacyPolicySummary/PrivacyPolicySummary";
import CookiesPolicy from "../views/CookiesPolicy/CookiesPolicy";
import NewDashboard from "../views/StepsCalculatorPage/Components/dashboard/NewDashboard";

import { COOKIES_POLICY_PAGE, PRIVACY_POLICY_PAGE, PRIVACY_POLICY_SUMMARY_PAGE, TERMS_AND_CONDITIONS_PAGE } from "./constants/publicRoutes";

const domain = process.env.REACT_APP_DOMAIN_LOCALE;

interface ConfigRoutesI {
  path: string;
  component: React.ReactNode;
}

export const redirects: ConfigRoutesI[] = [
  {
    path: "/",
    component: <Navigate to="/login" />,
  },
  {
    path: "/calculator",
    component: <Navigate to="/calculator/1" />,
  },
  {
    path: "/calculatorChallenger",
    component: <Navigate to="/calculatorChallenger/1" />,
  },
];

export const publicRoutes: ConfigRoutesI[] = [
  {
    path: "*",
    component: <NotFoundPage />,
  },
  {
    path: "/info",
    component: <PreStackMain />,
  },
  {
    path: "/notcoverage",
    component: <NotCoverage />,
  },
  {
    path: "/telesubscription",
    component: <Telesubscription />,
  },
  {
    path: "/partners/rastreator/",
    component: <RastreatorContainer />,
  },
  {
    path: "/partners/facile/",
    component: <FacilePartner />,
  },
  {
    path: "/pause",
    component: <IncomingUsersPage />,
  },
  {
    path: "/pauseAB",
    component: <IncomingUsersPage />,
  },
  {
    path: "/calculator/:stepCalculator",
    component: (
      <GetlifeCalculatorProvider>
        {{
          fr: <CalculatorPageFR />,
          es: <CalculatorPageES />,
          it: <CalculatorPageIT />,
          wl_es: <CalculatorPageWL_ES />,
          sql_es: <CalculatorPageSQL_ES />,
        }[domain!]}
      </GetlifeCalculatorProvider>
    ),
  },
  {
    path: "/calculatorChallenger/:stepCalculator",
    component: (
      <GetlifeCalculatorProvider>
        {{
          fr: <CalculatorPageFR />,
          es: <CalculatorPageES />,
          it: <CalculatorPageIT />,
          wl_es: <CalculatorPageWL_ES />,
        }[domain!]}
      </GetlifeCalculatorProvider>
    ),
  },
  {
    path: "/resumeVelogica",
    component: <ResumeVelogica />,
  },
  {
    path: "welcomeLead",
    component: <WelcomeLead />,
  },
  {
    path: "/waitingvalidation",
    component: <WaitingValidation />,
  },
  {
    path: "/agent-confirmation",
    component: <AgentConfirmation />,
  },
];

export const protectedRoutes: ConfigRoutesI[] = [
  {
    path: "/login/:otp/:next",
    component: <LoginOtpPage />,
  },
  {
    path: "/login/:otp",
    component: <LoginOtpPage />,
  },
  {
    path: "/pause",
    component: <IncomingUsersPage />,
  },
  {
    path: "/login",
    component: <LoginOtpPage />,
  },
  {
    path: "/otpDisabled",
    component: <OtpDisabled />,
  },
  {
    path: "/reset",
    component: <Reset />,
  },
  {
    path: "/resetDone",
    component: <PasswordChange />,
  },
  {
    path: "/signin",
    component: <LoginPage />,
  },
  {
    path: TERMS_AND_CONDITIONS_PAGE,
    component: <TermsAndConditions />
  },
  {
    path: PRIVACY_POLICY_PAGE,
    component: <PrivacyPolicy />
  },
  {
    path: PRIVACY_POLICY_SUMMARY_PAGE,
    component: <PrivacyPolicySummary />
  },
  {
    path: COOKIES_POLICY_PAGE,
    component: <CookiesPolicy />
  }
];

export const privateRoutes: ConfigRoutesI[] = [
  {
    path: "/animation",
    component: <PrePostStackAnimation />,
  },
  {
    path: "/beneficiaries/:step",
    component: <BeneficiariesFreeChoice />,
  },
  {
    path: "/beneficiaries/5",
    component: <BeneficiariesControlCapital />,
  },
  {
    path: "/card-validation",
    component: <CardValidation />,
  },
  {
    path: "/confirmation",
    component: <ConfirmationPage />,
  },
  {
    path: "/coverage/:step",
    component: <PostPaymentMain />,
  },
  {
    path: "/dashboard/:section",
    component: <NewDashboard />
  },
  {
    path: "/decision",
    component: <DecisionPage />,
  },
  {
    path: "/info/:emailParams",
    component: <PreStackMain />,
  },
  {
    path: "/payment/:stepPayment",
    component: <PaymentGateway />,
  },
  {
    path: "/paymentChallenger/:stepPayment",
    component: <PaymentGateway />,
  },
  {
    path: "/prestackQuestions",
    component: <PreStackMain />,
  },
  {
    path: "/productQuestions",
    component: <ProductStackQuestionsMain />,
  },
  {
    path: "/productQuestionsChallenger",
    component: <ProductStackQuestionsMain />,
  },
  {
    path: "/productQuestionsSurvey",
    component: <ProductStackQuestionsMain />,
  },
  {
    path: "/step/:step",
    component: <StepsCalculatorPage />,
  },
  {
    path: "/stepChallenger/:step",
    component: <StepsCalculatorPage />,
  },
  {
    path: "/waitingsignature",
    component: <WaitingSignature />,
  },
];
