import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import ChatWithTeam from "../../../views/StepsCalculatorPage/Components/ChatWithTeam";
import { allSteps } from "./components/questions";
import manualValidations from "../../../utils/ManualValidations";
import FormBuilder from "../../../components/Components/FormBuilder/FormBuilder";
import "../css/questions.scss";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { I18nContext } from "../../../context/i18n.context";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import { GetlifeContext, useQuery } from "../../../context/GetLifeContext.context";
import FormButton from "../../../components/Components/FormBuilder/FormButtons";
import { FormBuilderProvider } from "../../../context/FormBuilder.context";
import { DOMAIN } from "../../../constants/Global";

const PreStackContainer = (props) => {
  const navigate = useNavigate();

  const location = useLocation();

  let { emailParams } = useParams();

  let query = useQuery();

  const { init } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    leadId,
    loading,
    progressDecrement,
    progressIncrement,
    setLoading,
    token,
  } = props;

  const [actualStep, setActualStep] = useState(0);

  const [actualQuestion, setActualQuestion] = useState(false);

  const [email, setEmail] = useState(false);

  const [showIpa, setShowIpa] = useState(false);

  const [answer, setAnswer] = useState(null);

  const [capital, setCapital] = useState(false);

  const [chat, setchat] = useState(false);

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);

  const [goBack, setGoBack] = useState(false);

  const [dateError, setDateError] = useState(false);

  const [isNoSignup, setIsNoSignup] = useState(false);

  const handleGoBack = (e) => setGoBack(e);

  const handleChat = () => setchat(!chat);

  const allStepsResults = allSteps(translate);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const route = Location.pathname;
  const isOneChoinceQuestion =
    actualQuestion.type === "radio" &&
    actualQuestion.componentName &&
    route === "/stepChallenger/4";

  const postOrganicMail = async () => {
    await leadRepository.postOrganicEmail().then((res) => {
      const { id, token, status } = res;
      if (!token) {
        props.handleLeadId("");
        props.handleToken("");
        props.handleUserStatus("");
        // navigate("/login");
      } else {
        props.handleUserStatus(status);
        props.handleLeadId(id || leadId);
        props.handleToken(token || "");
      }
    });
  };

  const recoverNoSignUpLead = async (leadId) => {
    init("recover_not_sign_up_lead");
    props.handleLeadId(leadId);
    setActualStep(2);
    setIsNoSignup(true);
    leadRepository
      .getToken(leadId)
      .then((getTokenResponse) => {
        const { token } = getTokenResponse;
        props.handleToken(token);
      })
      .catch((e) => console.log("error", e));
  };

  useEffect(() => {
    const lead = query.get("lead");
    if (lead) {
      recoverNoSignUpLead(lead);
    } else if (!token) {
      postOrganicMail();
    }
  }, []);

  const handleAnswer = (e) => {
    if (actualStep === 0 && moment(e, "YYYY-M-D").isValid() === false) {
      setDateError(true);
    } else if (actualStep === 0 && moment(e, "YYYY-M-D").isValid() === true) {
      setDateError(false);
    }
    
    setAnswer(e);
  };

  const handleActualStep = (e) => {
    setActualStep(e);
  }

  const nextQuestion = () => {
    if (actualQuestion.name !== "ipa") {
      if (actualStep !== 5) {
        setActualStep(actualStep + 1);
      } else {
        if (showIpa === true) {
          setActualStep(actualStep + 1);
        } else {
          navigate("/productQuestions");
        }
      }
    } else {
      navigate("/productQuestions");
    }
  };

  const onBlur = (event) => {
    if (event) {
      validations(event.target.value);
    }
  };

  const validations = (value) => {
    if (actualQuestion.validations) {
      let validationResult = manualValidations(
        actualQuestion.validations,
        value
      );
      setError(validationResult.error);
      setErrorMessage(validationResult.errorMessage);
      if (validationResult.error) return false;
    }
  };

  const sendAnswer = async () => {
    if (validations(answer) === false || dateError === true) return false;

    setLoading(true);
      await leadRepository.postQuestionField({
        field: actualQuestion.name,
        value: answer,
      });
    nextQuestion();
    progressIncrement();
    setAnswer("");

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    if (emailParams === "family" || emailParams === "loan") {
      emailParams = undefined;
    }

    if (email === false) {
      if (emailParams === undefined) {
        setActualQuestion(allStepsResults[actualStep]);

        setLoading(false);
      } else {
        leadRepository.postOrganicEmail({ email: emailParams }).then((res) => {
          const { id, token } = res;

          if (token) {
            props.handleLeadId(id);
            props.handleToken(token || "");
          }
        });
        setEmail(emailParams);

        nextQuestion();

        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (token && leadId) {
      if (location.pathname === "/info") {
        if(isNoSignup) return;
        setActualQuestion(allStepsResults[0]);
      } else {
        navigate("/prestackQuestions");
        setActualStep(1);
      }
    }
  }, [token, leadId]);

  useEffect(() => {
    setActualQuestion(allStepsResults[actualStep]);
    setError(false);
    setErrorMessage(false);
  }, [actualStep]);

  useEffect(() => {
    if (actualQuestion.name === "capital" || actualQuestion.name === "gender") {
      setLoading(true);
      leadRepository
        .getQuestionField(allStepsResults[actualStep].name)
        .then((res) => {
          const data = res;
          const { answers } = data;

          if (actualQuestion.name === "capital") {

            const { minCapital, maxCapital, availableTraits } = answers;

            let step = minCapital >= 50000 ? 25000 : 10000;
            let ipa = availableTraits["DISABILITY"];

            setCapital({
              capitalRangueMin: minCapital,

              capitalRangueMax: maxCapital,

              capitalRangueStep: step,
            });
            setShowIpa(ipa);
          } else {
            const prevState = actualQuestion;
            setActualQuestion({ ...prevState, answers });
          }
          setLoading(false);
        });
    }
    
  }, [actualQuestion.name]);

  const lastQuestion = () => {
    if (
      location.pathname.search("coverage") === -1 &&
      location.pathname.search("beneficiaries") === -1
    ) {
      handleAnswer("");

      if (actualStep !== 0) {
        leadRepository
          .getQuestionField(allStepsResults[actualStep - 1].name)
          .then((res) => {
            const data = res;
            const { questionField, value } = data;

            if (questionField === "birthdate") {
              const splitedDate = value.split("-");
              const year = splitedDate[0];
              const daySplited = splitedDate[2].split("");
              const monthSplited = splitedDate[1].split("");
              const day =
                daySplited[0] === "0" ? daySplited[1] : splitedDate[2];

              const month =
                monthSplited[0] === "0" ? monthSplited[1] : splitedDate[1];

              const valueFormatted = `${year}-${month}-${day}`;

              handleAnswer(valueFormatted);
            } else {
              handleGoBack(true);

              handleAnswer(value);
            }
          });

        handleActualStep(actualStep - 1);
        progressDecrement();
      }
    } else if (
      location.pathname.search("coverage") !== -1 &&
      actualStep !== "0"
    ) {
      navigate(`/coverage/${actualStep - 1}`);
    } else if (
      location.pathname.search("beneficiaries") !== -1 &&
      actualStep !== "1"
    ) {
      navigate(`/beneficiaries/${actualStep - 1}`);
    } else if (location.pathname === "/beneficiaries/1") {
      navigate("/coverage/2");
    }
  };

  return (
    <FormBuilderProvider question={actualQuestion} answer={answer}>
      <div className={"questions-outerContainer prestack"}>
        <div className={"questions-container"}>
          {loading === false && (
            <>
              <div className={`questions-innerContainer ${DOMAIN === "it" ? "questions-innerContainer-it" : ""}`}>
                <FormBuilder
                  answer={answer}
                  capitalLimits={capital}
                  chat={chat}
                  className={dateError ? "birthdate-error" : ""}
                  error={error}
                  errorMessage={errorMessage}
                  goBack={lastQuestion}
                  handleChange={handleChat}
                  handleGoBack={handleGoBack}
                  handleSubmit={sendAnswer}
                  handleValues={handleAnswer}
                  isLoading={loading}
                  onBlur={onBlur}
                  question={actualQuestion}
                  showButton={true}
                  translate={translate}
                  width={"340px"}
                />
              </div>
            </>
          )}
          {chat && (
            <ChatWithTeam
              text={actualQuestion.tooltip}
              handleChat={handleChat}
            />
          )}
        </div>
        <FormButton
          showButton={true}
          isLoading={loading}
          isOneChoinceQuestion={isOneChoinceQuestion}
          componentName={actualQuestion.componentName}
          translate={translate}
          handleSubmit={sendAnswer}
          question={actualQuestion}
          answer={answer}
          goBack={lastQuestion}
          questionStack={actualQuestion.previousQuestionId ? [1] : []}
          isSticky={false}
        />
      </div>
    </FormBuilderProvider>
  );
};

export default PreStackContainer;
