import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { I18nContext } from "../../../../context/i18n.context";
import {
  ButtonDS,
  DrawerDS,
  GlobalIcon,
  IconButton,
  IconNameT,
} from "get-life-storybook-ts";
import AvatarIcon from "./components/AvatarIcon";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import { StatusContext } from "../../../../context/StatusContext";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { handleHubspot } from "../../../../utils/HandleHubspot";
import { DataI } from "../../../../api/request/Lead/Interfaces/DataInterface.response";

import "./NewDashboard.scss";
import { ProductI } from "../../../../api/request/Lead/Interfaces/ProductInterface.response";
import LoadingSpinner from "../../../Calculator/LoadingSpinner/LoadingSpinner";
import NewGlobalPosition from "./pages/NewGlobalPosition";
import numberFormat from "../../../../utils/Utils";
import { DOMAIN } from "../../../../constants/Global";
import HttpIntegrationsRepository from "../../../../api/request/Integrations/Integrations.service";
import NewProfile from "./pages/NewProfile";
import AllianzLogo from "../../../../assets/AllianzLogo";
import NewPolicy from "./pages/NewPolicy";
import { Elements } from "@stripe/react-stripe-js";
import { StripeConstructorOptions, loadStripe } from "@stripe/stripe-js";

type routesT = "global" | "policy" | "profile";

const NewDashboard = () => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { leadId, setToken, setLeadId, token, handleCallMe } =
    useContext(GetlifeContext);
  const { getStatus } = useContext(StatusContext);
  const leadRepository = new HttpLeadRepository(leadId, token);
  const integrationsRepository = new HttpIntegrationsRepository(leadId, token);

  const navigate = useNavigate();
  const { section } = useParams();
  const location = useLocation();
  const path = location?.pathname.split("/")[1];

  const [userData, setUserData] = useState<DataI | undefined>();
  const [product, setProduct] = useState<ProductI | undefined>();
  const [menu, setMenu] = useState<boolean>(false);

  const dataIsLoading = !(userData && product);

  const routes: {
    label: string;
    icon: IconNameT;
    route: routesT | "logout";
  }[] = [
    {
      label: translate("dashboard.menu.global"),
      icon: "Home2Icon",
      route: "global",
    },
    {
      label: translate("dashboard.menu.policy"),
      icon: "FileInvoiceIcon",
      route: "policy",
    },
    {
      label: translate("dashboard.menu.profile"),
      icon: "UsersIcon",
      route: "profile",
    },
    {
      label: translate("dashboard.menu.logout"),
      icon: "DoorExitIcon",
      route: "logout",
    },
  ];

  const navigateToRoute = (route: string) => {
    if (route === "logout") return logout();
    navigate(`/${path}/${route}`);
  };

  const logout = () => {
    setToken("");
    setLeadId("");
  };

  const getTokenCello = async () => {
    const token = await integrationsRepository.getTokenCello(leadId);

    return token.token;
  };

  useEffect(() => {
    getStatus();
    leadRepository.getCheckoutData().then((res) => {
      const data = res;
      if (data.code === 401) {
        navigate("/login");
      } else {
        setUserData(res);
      }
    });
    leadRepository.getCheckoutProduct().then((res) => {
      const data = res;
      setProduct(data);
    });
    handleHubspot();
  }, []);

  useEffect(() => {
    (async () => {
      if (DOMAIN === "es" || DOMAIN === "wl_es") {
        const token = await getTokenCello();
        // @ts-ignore
        window.cello = window.cello || { cmd: [] };
        // @ts-ignore
        window.cello.cmd.push(async function (cello: any) {
          try {
            await cello.boot({
              productId: process.env.REACT_APP_CELLO_PRODUCT_ID,
              token: token,
              language: "es",
            });
          } catch (error) {
            console.error("Failed to boot cello:", error);
            // Handle the error appropriately
          }
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
    {
      locale:
        (process.env
          .REACT_APP_DOMAIN_LOCALE as StripeConstructorOptions["locale"]) ||
        "es",
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    }
  );

  const sections: { [key in routesT]: JSX.Element } = {
    global: (
      <NewGlobalPosition
        userData={userData}
        product={product}
        navigateToRoute={navigateToRoute}
      />
    ),
    policy: (
      <Elements stripe={stripePromise}>
        <NewPolicy userData={userData} product={product} />
      </Elements>
    ),
    profile: <NewProfile userData={userData} />,
  };

  const Menu = ({ className = "" }) => {
    return (
      <div className={`dashboardMenu ${className}`}>
        <div className="dashboardMenu-avatar">
          <AvatarIcon className="dashboardMenu-avatarIcon" />
          <p className="BodyM dashboardMenu-name">
            {dataIsLoading ? (
              <div className="dashboardMenu-loadingBar" />
            ) : (
              `${userData?.lead.name} ${userData?.lead.lastName}`
            )}
          </p>
          <span className="dashboardMenu-online" />
        </div>
        <div className="dashboardMenu-options">
          {routes.map((r, idx) => {
            const { label, icon, route } = r;

            return (
              <button
                key={`${label}_${idx}`}
                className={`dashboardMenu-button ${
                  section === route ? "dashboardMenu-activeButton" : ""
                }`}
                onClick={() => {
                  navigateToRoute(route);
                  if (menu) setMenu(false);
                }}
                disabled={dataIsLoading}
              >
                <GlobalIcon iconName={icon} size="XS" color="currentColor" />
                <span className="BodyM">{label}</span>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const displayProfileHeader = section === "profile";
  const displayPolicyHeader = section === "policy";
  const isAllianz = DOMAIN === "it";

  return (
    <div className="dashboard">
      <Menu className="dashboardMenu-desktop" />
      <div className="dashboard-wrapper">
        {isAllianz ? (
          <div className="dashboard-allianz">
            <AllianzLogo />
          </div>
        ) : null}
        <div
          className={`dashboard-page ${
            isAllianz ? "dashboard-withAllianz" : ""
          }`}
        >
          {dataIsLoading ? (
            <div className="dashboard-loading">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="dashboard-heading">
                {!displayProfileHeader && !displayPolicyHeader ? (
                  <div className="dashboard-headingLabel">
                    <p className="H4 font-bold">
                      {translate("dashboard.menu.hello").replace(
                        "{name}",
                        userData?.lead.name || ""
                      )}
                    </p>
                    <p className="BodyM">
                      {translate("dashboard.menu.welcome")}
                    </p>
                  </div>
                ) : null}
                {displayProfileHeader ? (
                  <div className="dashboard-headingLabel">
                    <p className="H4 font-bold">
                      {translate("newDashboard.menu.profile.title")}
                    </p>
                    <p className="BodyM">
                      {translate("newDashboard.menu.profile.description")}
                    </p>
                  </div>
                ) : null}
                {displayPolicyHeader ? (
                  <div className="dashboard-headingLabel">
                    <p className="H4 font-bold">
                      {translate("newDashboard.menu.policy.title")}
                    </p>
                    <p className="BodyM">
                      {translate("newDashboard.menu.policy.description")}
                    </p>
                  </div>
                ) : null}
                <IconButton
                  icon="MenuIcon"
                  size="50"
                  buttonType="secondary"
                  onClick={() => setMenu(true)}
                  className="dashboard-mobile"
                />
                {displayProfileHeader ? null : (
                  <div className="dashboard-capital">
                    <GlobalIcon iconName="ShieldLockIcon" size="XS" />
                    <div className="dashboard-capitalLabel">
                      <span className="BodyM">
                        {translate("newDashboard.assuredCapital")}
                      </span>
                      <span className="H4">
                        {numberFormat(product?.capital, "capital")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid md:grid-cols-[1fr,256px] gap-[32px] mt-[16px]">
                {sections[section as routesT]}
                <div className="dashboard-card flex flex-col gap-[16px]">
                  <span className="bg-[var(--theme-quaternary)] rounded-full block w-fit h-fit mx-auto">
                    <GlobalIcon
                      iconName="QuestionMark"
                      size="S"
                      color="var(--dark-gray)"
                    />
                  </span>
                  <span className="BodyL font-bold text-[var(--dark-gray)]">
                    {translate("newDashboard.questions")}
                  </span>
                  <p className="BodyM text-[var(--dark-gray)]">
                    {translate("newDashboard.questionsDescription")}
                  </p>
                  <div className="flex flex-row gap-[12px]">
                    <GlobalIcon
                      iconName="CalendarTimeIcon"
                      size="XS"
                      color="var(--theme-primary)"
                    />
                    <span
                      className="flex-1 BodyM text-[#686868]"
                      dangerouslySetInnerHTML={{
                        __html: translate("newDashboard.schedule"),
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-[12px]">
                    <GlobalIcon
                      iconName="PhoneIcon"
                      size="XS"
                      color="var(--theme-primary)"
                      colorFill="transparent"
                    />
                    <span className="flex-1 BodyM text-[#686868] font-medium">
                      {translate("newDashboard.contactPhone")}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[12px]">
                    <GlobalIcon
                      iconName="MailIcon"
                      size="XS"
                      color="var(--theme-primary)"
                    />
                    <span className="flex-1 BodyM text-[#686868] font-medium">
                      {translate("newDashboard.contactEmail")}
                    </span>
                  </div>
                  <ButtonDS
                    label={translate("newDashboard.faqButton")}
                    rightIcon="QuestionMark2Icon"
                    href="https://life5.es/preguntas-frecuentes/"
                    target="_blank"
                    size="32"
                    className="!no-underline"
                  />
                  <ButtonDS
                    label={translate("newDashboard.bookCall")}
                    leftIcon="CalendarEventIcon"
                    size="32"
                    className="!no-underline"
                    buttonType="secondary"
                    onClick={handleCallMe}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DrawerDS
        open={menu}
        onClose={() => setMenu(false)}
        fitWidth={true}
        content={<Menu />}
      />
    </div>
  );
};

export default NewDashboard;
