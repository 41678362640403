import React from "react";
import { useNavigate } from "react-router";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { calculateDiffDays, formatDate } from "../../utils/Dates.utils";
import numberFormat, { capitalize, numberFormatPayment } from "../../utils/Utils";
import { redirect } from "../../Routes/redirect";
import { DOMAIN } from "../../constants/Global";

export type DataBlockIconT = 'card' | 'user' | 'check' | 'umbrella' | 'calendar' 

interface DataBlockInfoI {
    title: string,
    subtitle: string,
    text: string,
    icon: DataBlockIconT,
    space: boolean
}

const ConfirmationPageController = () => {
  const { leadId, token, handleCallMe, userStatus, setUserStatus, isABtesting } =
    React.useContext(GetlifeContext);
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const [data, setData] = React.useState<any>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<any>();
  const [couponInfo, setCouponInfo] = React.useState<any>();
  const [progression, setProgression] = React.useState<any>();
  const [dataBlocksInfo, setDataBlocksInfo] = React.useState<DataBlockInfoI[]>()

  const leadRepository = new HttpLeadRepository(leadId, token);
  const couponRepository = new HttpCouponsRepository(leadId, token);
  const navigate = useNavigate();

  const intentionMessage = () => {
    if (!product.intention) {
      return "";
    }
    const hasMultipleIntention = product.intention.search(",");
    const intention =
      hasMultipleIntention != -1
        ? product.intention.split(", ").reverse()
        : product.intention;
    if (typeof intention === "string") {
      return translate("confirmationPage.intention.protect").replace(
        "$",
        translate(`confirmationPage.intention.${intention}`)
      );
    } else if (intention.length === 2) {
      const combineIntention = `${translate(
        `confirmationPage.intention.${intention[0]}`
      )} ${translate("confirmationPage.intention.conjuction")} ${translate(
        `confirmationPage.intention.${intention[1]}`
      )}`;
      return translate("confirmationPage.intention.protect").replace(
        "$",
        combineIntention
      );
    } else {
      const combineIntention = `${translate(
        `confirmationPage.intention.${intention[0]}`
      )}, ${translate(
        `confirmationPage.intention.${intention[1]}`
      )} ${translate("confirmationPage.intention.conjuction")} ${translate(
        `confirmationPage.intention.${intention[2]}`
      )}`;
      return translate("confirmationPage.intention.protect").replace(
        "$",
        combineIntention
      );
    }
  };

  const continueProcess = () => {
    navigate(redirect(userStatus));
  };
  const fetch = async () => {
    const response = await leadRepository.getInfoLead();
    setUserStatus(response.status);
    setProgression([
      {
        description: translate(
          "confirmationPage.Progression.confirmMethod.description"
        ),
        title: translate("confirmationPage.Progression.confirmMethod.title"),
        active: response.basicInfo && "active",
      },
      {
        description: translate(
          "confirmationPage.Progression.personalData.description"
        ),
        title: translate("confirmationPage.Progression.personalData.title"),
        active: response.paidAndSigned && "active",
      },
      {
        description: translate(
          "confirmationPage.Progression.final.description"
        ),
        title: translate("confirmationPage.Progression.final.title"),
        active:
          response.basicInfo &&
          response.healthQuestions &&
          response.paidAndSigned &&
          "active",
      },
    ]);
  };

  const fetchCoupons = async ({ code, yearlyprice }: any) => {
    try {
      const response = await couponRepository.checkPromotionalCode({
        promotionalCode: code,
        yearlyPrice: yearlyprice,
      });
      if (response.is_applicable_promo) {
        setCouponInfo({
          conditions: response.promo_conditions,
          exceptions: response.promo_exceptions,
          code: response.promo,
        });
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    (async () => {
      leadRepository.getCheckoutData().then(setData);
      leadRepository.getCheckoutProduct().then((prod) => {
        setProduct(prod);
        if (prod.promotion && prod.promotion.promotionalCode) {
          fetchCoupons({
            code: prod.promotion.promotionalCode,
            yearlyprice: prod.finalYearlyPrice,
          });
        }
      });
      fetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(dataBlocksInfo === undefined && data && product){
      setTimeout(() => {
        setDataBlocksInfo([
          {
            title: translate("confirmationPage.resume.insured"),
            subtitle: `${capitalize(data.lead.name)} ${capitalize(
              data.lead.lastName
            )}`,
            text: formatDate(data.lead.birthdate),
            icon: "user",
            space: false
          },
          {
            title: translate("confirmationPage.resume.capital"),
            subtitle: numberFormat(data.lead.capital, "capital") || "",
            text: intentionMessage(),
            icon: "check",
            space: true
          },
          {
            title: translate("confirmationPage.resume.coverage"),
            subtitle: data.coverage === "basic" ? translate("confirmationPage.resume.insurance") : data.coverage === "eg" ? translate("confirmationPage.resume.eg"): translate("confirmationPage.resume.insuranceIPA"),
            text:
              data.coverage === "basic"
                ? ""
                : data.coverage === "eg" ? `${translate(`confirmationPage.coverage.eg`)} (${numberFormat(data.lead.capital, "capital")})`
                :`${translate(`confirmationPage.coverage.${data.coverage}`)}`,
            icon: "umbrella",
            space: true
          },
          {
            title: translate("confirmationPage.resume.startDate"),
            subtitle: new Date(product.effectDate).toLocaleDateString(),
            text: `${calculateDiffDays(product.effectDate, translate)} `,
            icon: "calendar",
            space: true
          },
          {
            title: translate("confirmationPage.resume.bonus"),
            subtitle: price() || "",
            text: translate(`confirmationPage.period.${product.period?.toLowerCase()}`),
            icon: "card",
            space: true
          },
        ])
      }, isABtesting ? 3000 : 0);
    }
  }, [data, product])

  const price = () => {
    const totalPrice =
      product.period?.toLowerCase() === "yearly"
        ? data.priceSummary.priceToPay
        : data.priceSummary.regularPrice;
    return `${numberFormatPayment(totalPrice)} €`;
  };



  return {
    continueProcess,
    couponInfo,
    data,
    dataBlocksInfo,
    handleCallMe,
    intentionMessage,
    open,
    price,
    product,
    progression,
    setOpen,
    translate,
    isABtesting
  };
};

export default ConfirmationPageController;
