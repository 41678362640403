import { ButtonDS } from 'get-life-storybook-ts';
import icon from './../../../assets/img/chatWithTeam/Group 42.png';
import './css/chatWithTeam.scss';

export default function ChatWithTeam(props) {
    const { intercomState, showIntercom, hideIntercom } = props;

    return (
        <div className="chatWithTeam-container">
            <p style={{ cursor: 'pointer' }} onClick={props.handleChat}>
                x
            </p>
            <p>INFORMACIÓN</p>
            <p style={{ fontSize: '20px' }}>
                Te contámos <b>por qué</b>
            </p>
            <p className={'explanation'}>{props.text}</p>
            <div className="chatWithTeam-bodyContainer">
                <img src={icon} alt="chatea con nosotros" />
                <div className="chatWithTeam-inputsContainer">
                    <input
                        disabled="disabled"
                        value="¿Aún sigues con dudas?"
                        className="tooltip-question"
                    />
                    <textarea
                        disabled="disabled"
                        value="Si tienes alguna pregunta que hacernos o sigues teniendo dudas, estaremos encantados de responderte."
                        className="tooltip-text"
                    ></textarea>
                    <ButtonDS
                        buttonType="secondary"
                        className="w-full"
                        label="Hablar con el equipo"
                        onClick={intercomState === false ? showIntercom : hideIntercom}
                    />
                </div>
            </div>
        </div>
    );
}
