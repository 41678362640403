export default async function apiGetDownloadableFiles(
    leadId,
    translate,
    coverage
  ) {
    const pdfs = [];
    const lang = process.env.REACT_APP_DOMAIN_LOCALE;
  
    let template = "";
    if (["es","wl_es"].includes(lang)) {
      if (coverage === "basic") template = "?templates=offer-w-base";
      if (coverage === "premium")
        template = "?templates=offer-w-base,ipa-any-cause-exclusions";
    }
  
    const conditionsPdf = await fetch(
      `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/documents/budget/${leadId}${template}`
    )
      .then((response) => response.json())
      .then((data) => data.url)
      .catch(() => null);
  
    if (conditionsPdf) {
      pdfs.push({
        label: translate("downloadableFiles.conditionsPdf"),
        href: conditionsPdf,
      });
    }
  
    const notesPdf = await fetch(
      `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/lead/note/${leadId}`
    )
      .then((response) => response.json())
      .then((data) => data.url)
      .catch(() => null);
  
    if (notesPdf)
      pdfs.push({
        label: translate("downloadableFiles.notesPdf"),
        href: notesPdf,
      });
  
    const IPID = {
      fr: "https://storage.googleapis.com/getlife-bucket-1/public/pdf/IPID-GTD.pdf",
    };
    if (IPID[lang])
      pdfs.push({
        label: translate("downloadableFiles.ipidPdf"),
        href: IPID[lang],
      });
    return pdfs;
  }
  