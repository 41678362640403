import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import PreStackContainer from "./PreStackContainer";
import { handleHubspot } from "../../../utils/HandleHubspot";
import { GetlifeContext } from "../../../context/GetLifeContext.context";

export default function PreStackMain() {
  const query = queryString.parse(window.location.search);

  if (query["aid"]) {
    localStorage.setItem("aid", query["aid"]);
  }

  const [progressBar, setProgressBar] = useState(5);

  const {
    leadId,
    setLeadId,
    setToken,
    token,
    setUserStatus,
    setLoading,
    loading,
  } = useContext(GetlifeContext);

  const progressIncrement = () => setProgressBar(progressBar + 5);

  const progressDecrement = () => setProgressBar(progressBar - 5);

  useEffect(() => {
    handleHubspot();
  }, []);

  return (
    <PreStackContainer
      handleLeadId={setLeadId}
      handleToken={setToken}
      handleUserStatus={setUserStatus}
      leadId={leadId}
      loading={loading}
      progressAmount={progressBar}
      progressDecrement={progressDecrement}
      progressIncrement={progressIncrement}
      setLoading={setLoading}
      token={token}
    />
  );
}
